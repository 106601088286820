<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="cons">
        <div class="sctptitle">
          <el-button type="primary" @click="clicksctp">上传文档</el-button>
        </div>
        <div class="sctplist">
          <el-table :data="tableData" border style="width: 100%"  v-loading="loading" :element-loading-text="loadingtext">
            <el-table-column prop="sourceFileName" label="文档名称" />
            <el-table-column prop="createTime" sortable label="上传时间" />
            <el-table-column
              prop="status"
              sortable
              label="排版状态"
              width="150"
            >
              <template #default="scope">
                <el-tag
                  class="ml-2"
                  :type="
                    scope.row.status == 1
                      ? 'info'
                      : scope.row.status == 2
                      ? 'danger'
                      : scope.row.status == 9
                      ? ''
                      : 'success'
                  "
                  >{{
                    scope.row.status == 1
                      ? "待排版"
                      : scope.row.status == 2
                      ? "排版失败"
                      : scope.row.status == 9
                      ? "排版完成"
                      : "状态未知"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template #default="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  size="small"
                  type="info"
                  @click="clickkspb(scope.row.id)"
                  >开始排版</el-button
                >
                <el-button
                  v-if="scope.row.status == 2"
                  size="small"
                  type="warning"
                  @click="clickkspb(scope.row.id)"
                  >重新排版</el-button
                >
                <el-button
                  v-if="scope.row.status == 9"
                  size="small"
                  type="primary"
                  @click="downloadFileStream(scope.row.id)"
                  >下载文档</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="sctplist-fy">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totals"
              :current-page="currentPage"
              @current-change="fanye"
            />
          </div>
        </div>
      </div>
    </div>
    <a href=""></a>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();


// loading
let loading = ref(false);
let loadingtext=ref('')
let tableData = reactive([]);
// 分页
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);
// 分页
const fanye = (val) => {
  page.value = val;
  currentPage.value = val;
  getlist();
};
// 获取数据
const getlist = () => {
  post("/wordFormat/page", {
    page: page.value,
    pageSize: 10,
  }).then((res) => {
    // console.log(res);
    tableData.length = 0;
    tableData.push(...res.result.items);
    totals.value = res.result.total;
  });
};
getlist();

// 开始排版
const clickkspb = (id) => {
  loading.value = true;
  loadingtext.value='排版中...'
  get("/wordFormat/settype/" + id)
    .then((res) => {
      loading.value = false;
      ElMessage({
        message: "排版完成",
        duration: 1000,
        onClose: () => {
          // location.reload();
          getlist()
        },
      });
    })
    .catch((err) => {
      loading.value = false;
    });
};

// 下载文档
const downloadFileStream = (id) => {
  loading.value = true;
  loadingtext.value='下载中...'
  const urlapi = "https://titletools-api.yuanpingjia.cn";
  const xhr = new XMLHttpRequest();
  xhr.open("get", urlapi + "/wordFormat/download/" + id, true);
  xhr.setRequestHeader(
    "Content-type",
    "application/json-patch+json;charset=UTF-8"
  );
  xhr.setRequestHeader(
    "Authorization",
    "Bearer " + window.localStorage.getItem("token")
  );
  //定义responseType='blob', 是读取文件成功的关键，这样设置可以解决下载文件乱码的问题
  xhr.responseType = "blob";
  xhr.onreadystatechange = () => {
    loading.value = false;
    if (xhr.readyState == 4 && xhr.status == 200) {
      let fileName = xhr
        .getResponseHeader("Content-Disposition")
        .split(";")[1]
        .split("=")[1]
        .replace(/\"/g, "");
      let type = xhr.getResponseHeader("content-type");
      let blob = new Blob([xhr.response], { type: type });
      //对于<a>标签，只有 Firefox 和 Chrome（内核）支持 download 属性
      //IE10以上支持blob，但是依然不支持download
      if ("download" in document.createElement("a")) {
        //支持a标签download的浏览器
        //通过创建a标签实现
        let link = document.createElement("a");
        //文件名
        link.download = decodeURIComponent(fileName);
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); //执行下载
        URL.revokeObjectURL(link.href); //释放url
        document.body.removeChild(link); //释放标签
      } else {
        //不支持
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
      }
    }
  };
  xhr.send();
};

// 跳转上传
const clicksctp = () => {
  router.push({
    path: "/znpb/sbu",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.sctptitle {
  margin-bottom: 20px;
}
.sctplist-fy {
  margin-top: 20px;
}
</style>
